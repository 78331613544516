    import React from 'react';

    import Auxi from '../../hoc/Auxi'
    import '../../assets/css/style.css'
    import Avatar from '../../assets/images/user/avatar.png';

    const header = (props) => (

    <Auxi>
        <header className="navbar pcoded-header navbar-expand-lg navbar-light">

            <div className="collapse navbar-collapse">

                <ul className="navbar-nav ml-auto">

                    <li className="mouse-pointer">
                        <h6>ACCOUNT SETTINGS</h6>
                    </li>
                    <li className="pro-head-li">
                        <div className="dropdown drp-user">
                            <a  onClick={props.clickSettings} href="" data-toggle="dropdown">
                                <div  className="pro-head pro-head-custom">
                                    <img src={Avatar} className="img-radius" />
                                </div>
                            </a>

                            <div className="dropdown-menu -custom dropdown-menu-right-custom profile-notification"
                                style={{display:props.displaySettingsMenu}}>
                                <div className="pro-head">

                                    <img src={Avatar} className="img-radius" alt="User-Profile"/>
                                    <span id = "user_name">{props.userName} - {props.companyName}</span>
                                    <a href="" onClick = {props.clickedLogout} className="dud-logout" title="Logout">
                                        <i  className="feather icon-log-out"> </i>
                                    </a>
                                </div>
                                <ul className="pro-body">
                                    {/* <li><a href="" className="dropdown-item" onClick={props.clickedProfile}><i
                                        className="feather icon-user"> </i> Profile</a></li> */}
                                    
                                    <li><a  href="" className="dropdown-item" onClick={props.clickedChangePassword}><i
                                        className="feather icon-lock"> </i> Change Password</a></li>
                                        
                                    {/* <li><a  href="" className="dropdown-item" ><i
                                        className="feather icon-settings"> </i> Settings</a></li>
                                         */}
                                    
                                    {/*<li><a  className="dropdown-item"><i*/}
                                        {/*className="feather icon-mail"> </i> My Messages</a></li>*/}
                                    {/*<li><a onClick={this.LoginSwitch} className="dropdown-item"><i*/}
                                    {/*    className="feather icon-lock"> </i>Log out</a></li>*/}
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </header>
    </Auxi>

    );

    export default header;

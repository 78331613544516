import * as ActionTypes from './../actions/ActionTypes'
import { updateObject} from "./../utility";


const initialState={
    token:null,
    loading:null,
    error:null,
};



const authSuccess=(state,action)=>{
    return updateObject(state,{
        token:action.access,
        loading:false,
        error:null
    })
};

const authFail=(state,action)=>{
    return updateObject(state,{
        error:action.error,
        loading:false
    })
};

const errorNull=(state,action)=>{
    return updateObject(state,{
        error:action.error
    })
};

const authLogout=(state,action)=>{
    return updateObject(state,{
        token:null,
        loading:false,
        error:null
    })
};

const reducer=(state=initialState,action)=>{

    switch(action.type){
        case ActionTypes.AUTH_SUCCESS: return authSuccess(state,action);
        case ActionTypes.AUTH_FAIL: return authFail(state,action);
        //case actionTypes.AUTH_LOGOUT: return authLogout(state,action);
        case ActionTypes.AUTH_ERROR: return errorNull(state,action);
        case ActionTypes.AUTH_LOGOUT: return authLogout(state,action);
        default:
            return state
    }
};

export default reducer

import React from 'react';
import { Redirect } from 'react-router';
import { MDBDataTableV5 } from 'mdbreact';
import axios from 'axios';
import moment from 'moment';

import Auxi from '../../hoc/Auxi';
import Layout from '../Layout/Layout';
import Spinner from '../../components/Widgets/Spinner';
import Toast from '../../components/Widgets/CustomToast';

import simImage from '../../assets/images/iccid_image.png'


import Navbar from './Navbar'

import '../../assets/css/preactivation.css';

class Preactivation extends React.Component{

    state = {
        data: {}
    };

    componentDidMount() {

        const url = new URL(window.location.href);
        const key = url.searchParams.get("key");
        this.setState({key: key});
        document.title = 'SIM card activation - Fonus';
    }

    inputChangeHandler = (event) => {
		var data = this.state.data;
		data[event.target.name] = event.target.value;
		this.setState({ data });
	}

    submitHandler= ()=> {
        if (!this.state.data.iccid){
            Toast.error("Please input ICCID")
            return;
        }
        const payLoad = {
            key: this.state.key,
            iccid: this.state.data.iccid,
        };
        
        axios.post(`${process.env.REACT_APP_API_URL}validateICCIDAndActivate`, payLoad, )
        .then(response => {
            window.location.replace("https://www.fonusmobile.com/sim-activated/");
        }).catch(err => {
            Toast.error(err.response.data)
        });
    }

    render() {


        return (
            <Auxi>
                <Navbar/>

                <div className = "activation-page">
                    <div className="row outer-container">
                        <div className="col-md-12">
                            <h1 className="main-heading"> Activate your Fonus SIM </h1>
                        </div>

                        {/* <div className="container">

                            <div className="item-image">
                                <img src="https://www.fonusmobile.com/wp-content/uploads/2023/06/E58F4BC1-78D6-403B-85C7-84ECFEF6B0BE-scaled-e1687793035737-1024x583.jpeg.webp"
                                width="400"/>
                            </div>

                            <div className="item-heading1">
                                <h2> Please provide SIM info  </h2>
                            </div>

                            <div className="item-heading2">
                                <h5> Help us identify your fonus SIM</h5>
                            </div>

                            <div className="item-input">
                                <input type="text"></input>
                            </div>

                            <div className="item-button">
                                <button>Submit</button>
                            </div>
                        </div> */}

                        <div className="col-md-12 info-container">
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <div className="item-image">
                                        <img src={simImage}
                                        width="550"/>
                                    </div>
                                </div>

                                <div className="col-md-6 col-12">
                                    <div className="item-heading1">
                                        <p> 
                                            <strong>Enter the 19-digit ICCID number</strong> located on <br/>the back of your SIM card  
                                        </p>
                                    </div>

                                    <div className="item-input">
                                        <label>SIM card number / ICCID</label>
                                        <input type="text"
                                            onChange={this.inputChangeHandler}
                                            name="iccid"
                                            value={this.state.data.iccid}
                                            placeholder='19-digit ICCID'/>
                                    </div>

                                    <div className="item-button">
                                        <button onClick={this.submitHandler}>Activate</button>
                                    </div>

                                    <div className="end-note">
                                        <p>
                                            <i>
                                            Please note that this activation process is for <b>physical SIMs only.</b>  If you’ve purchased an eSIM already along with one of our plans, you do not need to activate it – the eSIM you received via email is already active.
                                            </i>
                                        </p>
                                    </div>
                                    
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </Auxi>

        );

    }

}

export default Preactivation;

    import React,{useState} from 'react';
    import axios from 'axios';

    import Modal from 'react-modal';

    import './Modal.css';
    import '../../../assets/css/style.css'
    import Auxi from '../../../hoc/Auxi';
    import Toast from '../CustomToast';

    const customStyles = {
        content : {
            top                   : '50%',
            left                  : '50%',
            right                 : 'auto',
            bottom                : 'auto',
            marginRight           : '-50%',
            transform             : 'translate(-50%, -50%)'
        }
    };

    const ChangePasswordModal = (props) => {

        const [oldPassword, setOldPassword] = useState('');
        const [newPassword, setNewPassword] = useState('');
        const [confirmPassword, setConfirmPassword] = useState('');
        const [validation, setValidation] = useState('');

        var subtitle;

        function afterOpenModal() {
            setOldPassword('');
            setNewPassword('');
            setConfirmPassword('');
            setValidation('');
        }

        const changePasswordHandler = (evt) => {
            if(newPassword == '' || oldPassword == '' || confirmPassword == ''){
                setValidation('All fields are Mandatory!');
                return;
            }
            if(newPassword != confirmPassword){
                setValidation('New and Confirm Passwords does not match!');
                return;
            }
            const payload = {
                cloud_id: localStorage.getItem('cloud_id'),
                username: localStorage.getItem('userName'),
                old_password: oldPassword,
                new_password: newPassword
            }
            axios.post(`${process.env.REACT_APP_API_URL}changePassword`, payload, { withCredentials: true })
            .then(response=>{
                //this.createExtension();
                Toast.success("Successfully Changed the password!");
                setOldPassword('');
                setNewPassword('');
                setConfirmPassword('');
                setValidation('');

            })
            .catch(err=>{
                
                Toast.error(err.response.data);
            });
        }


        return (
            <div className = "forward-modal">

                <Modal
                    isOpen={props.modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={props.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div className="card-body text-center" style={{width:"250px"}}>
                        <h5> Change password </h5>
                        <span className="modal-cross" onClick={props.closeModal}>x</span>
                        <div className="row form-group">

                            <div className="input-group mb-3">
                                <input type="password" className="form-control" placeholder="Old Password"
                                    value={oldPassword} name="oldPassword"
                                    onChange={(evt) => {
                                        evt.preventDefault();
                                        if(validation != ''){
                                            setValidation('');
                                        }
                                        setOldPassword(evt.target.value)
                                    }}/>
                            </div>

                            <div className="input-group mb-3">
                                <input type="password" className="form-control" placeholder="New Password"
                                    value={newPassword} name="newPassword"
                                    onChange={(evt) => {
                                        evt.preventDefault();
                                        if(validation != ''){
                                            setValidation('');
                                        }
                                        setNewPassword(evt.target.value)
                                    }}/>
                            </div>

                            <div className="input-group mb-4">
                                <input type="password" className="form-control" placeholder="Confirm Password"
                                    value={confirmPassword} name="confirmPassword"
                                    onChange={(evt) => {
                                            evt.preventDefault();
                                            if(validation != ''){
                                                setValidation('');
                                            }
                                            setConfirmPassword(evt.target.value)
                                        }}/>
                            </div>

                        </div>
                            {
                                validation != ''?<Auxi><span className='login-error'>
                                {validation}</span> <br/><br/></Auxi>
                                :null
                            }
                        <button className="btn btn-primary mb-4" onClick = {changePasswordHandler}>
                                Change
                        </button>
                    </div>
                        
                </Modal>
            </div>
        );

    };

    export default ChangePasswordModal;

import React from 'react';
import { Redirect } from 'react-router';
import { MDBDataTableV5 } from 'mdbreact';
import axios from 'axios';
import moment from 'moment';
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";

import Auxi from '../../hoc/Auxi';
import Layout from '../Layout/Layout';
import Spinner from '../../components/Widgets/Spinner';
import Toast from '../../components/Widgets/CustomToast';

import '../../assets/css/style.css';

class CDR extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
        newRecordClicked: false,
        cdrIdSelected: null,
        loading: true,
        fromDate: null,
        toDate: null,
      };
  }

  headers = [
    { label: "Timestamp", key: "created_time" },
    { label: "From User", key: "from_user" },
    { label: "To User", key: "to_user" },
    { label: "Direction", key: "direction" }
  ];

  componentDidMount() {

    const date = new Date();
    this.setState({toDate: new Date()});
    const fromDate = (date.setDate(date.getDate() - 1))
    this.setState({fromDate: fromDate});

    this.refreshCdr(fromDate, new Date())
  }

  refreshCdr = (fromDate, toDate) => {
    const payLoad = {
      cloud_id: localStorage.getItem('cloud_id'),
      from_date: moment(fromDate).format('YYYY-MM-DD'),
      to_date: moment(toDate).format('YYYY-MM-DD'),
    };

    axios.post(`${process.env.REACT_APP_API_URL}get/cdr`, payLoad, { withCredentials: true })
    .then(response=> {
        this.createDataTable(response.data);

      })
    .catch(err=> {
        Toast.error(err.message);
      });
  }

  createDataTable = (dataFromApi) => {

      let columns = [
          
          {
            label: 'Timestamp',
            field: 'created_time',
          },
          {
              label: 'From User',
              field: 'from_user',
          },
          {
              label: 'To User',
              field: 'to_user',
          },
          {
            label: 'Direction',
            field: 'direction',
          },
          
      ];

      if (dataFromApi != null)
      {
        dataFromApi = dataFromApi.map((row) => {
          row.created_time = moment(row.created_time).format("LLL")

          return ({ ...row });
        });
      }
      
      this.setState({csvData: dataFromApi});
      this.setState({ dataTable: { columns: columns, rows: dataFromApi } });
      this.setState({ loading: false });
    };

    toDateChangeHandler = (date) => {
      this.setState({toDate: date});
      this.refreshCdr(this.state.fromDate, date);
    }

    fromDateChangeHandler = (date) => {
      this.setState({fromDate: date});
      this.refreshCdr(date, this.state.toDate);
    }

  render() {

    

    return (
        <Auxi>
                <Layout />

                <div className = "MainIndex">
                    <div className="page-header customTitleMiddle">
                        <h3 className="m-b-10">
                            {this.state.loading
                          ? <Spinner data="CDR ..." />
                          : 'CDR'
                        }
                        </h3>
                    </div>
                    <div className="row form-group">

                        <div className="col-md-4">
                            <label className="float-left" style={{marginRight: "10px"}}>
                                From Date
                            </label>
                          
                            {this.state.fromDate?
                              <DatePicker selected={this.state.fromDate} onChange={this.fromDateChangeHandler} />
                              : null
                            }
                        </div>

                        <div className="col-md-4">
                            <label className="float-left" style={{marginRight: "10px"}}>
                                To Date
                            </label>
                            {this.state.toDate? 
                              <DatePicker selected={this.state.toDate} onChange={this.toDateChangeHandler} />
                              : null
                            }
                            
                        </div>

                        <div className="col-md-4" style={{textAlign: "end"}}>
                          {this.state.csvData? 
                            <button type="button"  className="btn btn-rounded-custom btn-primary export-button">
                              <CSVLink data={this.state.csvData} headers={this.headers} filename="FONUS_CDR.csv">Export to CSV</CSVLink>
                            </button> 
                          : null }
                        </div>

                        
                    </div>
                    <div className="row" style={{float:"right"}}>
                      
                    </div>
                    <div className="">
                      <MDBDataTableV5 hover entriesOptions={[10, 20, 30]} entries={10}
                        pagesAmount={4} data={this.state.dataTable} searchTop  searchBottom={false} />

                    </div>
                </div>

            </Auxi>

    );

  }

}

export default CDR;

import React,{useState} from 'react';
import axios from 'axios';

import Modal from 'react-modal';

import '../../assets/css/voicemailGreeting.css';
import {Recorder} from 'react-voice-recorder'
import 'react-voice-recorder/dist/index.css'

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    }
};

const VoiceRecorderModal = (props) => {


    return (
        <div className = "forward-modal">

            <Modal
                isOpen={props.modalIsOpen}
                onRequestClose={props.closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="recorder-container">
                    <i class="fa fa-times-circle cross-btn" aria-hidden="true" onClick={props.closeModal}></i>

                    <Recorder
                        record={true}
                        title={"New Recording"}
                        audioURL={props.audioDetails.url}
                        showUIAudio
                        handleAudioStop={data => props.handleAudioStop(data)}
                        handleAudioUpload={data => props.handleAudioUpload(data)}
                        handleCountDown={data => props.handleCountDown(data)}
                        handleReset={() => props.handleReset()}
                        mimeTypeToUseWhenRecording={props.mimeType} // For specific mimetype.
                    />
                </div>
                    
            </Modal>
        </div>
    );

};

export default VoiceRecorderModal;

import React from 'react';
import { Redirect } from 'react-router';
import { MDBDataTableV5 } from 'mdbreact';
import axios from 'axios';
import moment from 'moment';

import Auxi from '../../hoc/Auxi';
import Layout from '../Layout/Layout';
import Spinner from '../../components/Widgets/Spinner';
import Toast from '../../components/Widgets/CustomToast';

import simImage from '../../assets/images/iccid_image.png'


import '../../assets/css/numberSelection.css';

class NumberSelection extends React.Component{

    state = {
        cloudId : "FONUSMOBILE",
        backToMyAccount: false,
        loading: true,
    };

    componentDidMount() {
        const encodedUrlString = window.location.href.replace(/\+/g, '%2B');
        const url = new URL(encodedUrlString);
        const cloudUsername = url.searchParams.get("cloud_username");
        const cloudPassword = url.searchParams.get("cloud_password");
        this.setState({cloudUsername: cloudUsername});
        this.setState({cloudPassword: cloudPassword});
        document.title = 'Number Selection - Fonus';

        this.getDidList(cloudUsername, cloudPassword);
    }

    getDidList = (cloudUsername, cloudPassword) => {
        const payLoad = {
            cloud_username: cloudUsername,
            cloud_password: cloudPassword,
            cloud_id: this.state.cloudId
        };
        
        axios.post(`${process.env.REACT_APP_API_URL}getDidsForUser`, payLoad)
        .then(response => {
            let numbers = response.data.map((row, index) => {
                if(row.default_did){
                    this.setState({defaultDidId: row.id});
                }
                return (
                    <div key={"number"+index} className="number-container">
                        <h4 onClick={e => this.numberchangeHandler(row.id)}>
                            {row.did_number}
                        </h4>
                        {
                            row.default_did?
                                <span>
                                    <i className="feather icon-check"> </i>
                                </span>
                            : null
                        }
                    </div>
                )
            });
            this.setState({numbers: numbers})
            this.setState({loading: false});
        }).catch(err => {
            // Toast.error(err.response.data)
            this.setState({loading: false});
        });
    }


    numberchangeHandler= (newDidId)=> {
        if (newDidId === this.state.defaultDidId){
            return;
        }
        this.setState({loading: true});
        const payLoad = {
            cloud_username: this.state.cloudUsername,
            cloud_password: this.state.cloudPassword,
            cloud_id: this.state.cloudId,
            old_did_id: this.state.defaultDidId,
            new_did_id: newDidId,
        };
        
        axios.post(`${process.env.REACT_APP_API_URL}changeDefaultDid`, payLoad, )
        .then(response => {
            this.getDidList(this.state.cloudUsername, this.state.cloudPassword);
        }).catch(err => {
            Toast.error(err.response.data)
            this.setState({loading: false});
        });
    }

    render() {
        let redirect = null;
        if(this.state.backToMyAccount){
            this.setState({backToMyAccount: false});
            redirect = <Redirect to = {"/my_account?cloud_username="+this.state.cloudUsername+"&cloud_password="+this.state.cloudPassword} />;

        }

        return (
            <Auxi>
                {redirect}
                <div className = "number-selection-page">
                    <div className="row outer-container">
                        <h3 className="m-b-10 loader-center">
                            {this.state.loading
                                ? <Spinner data="" />
                                : null
                            }
                        </h3>
                        <div className="col-md-12 number-selection-heading">
                            <i className="feather icon-chevron-left" onClick={e => this.setState({backToMyAccount: true})}> </i>
                            <h1> My Numbers </h1>
                        </div>

                        <div className="col-md-12">
                            <div className="number-item-heading1">
                                <p> 
                                    Choose the number you wish to use. The selected number will be used to send outbound calls and messages.  
                                </p>
                                <p>If you wish to port in your existing number from another carrier, contact our support team via live chat available 24/7 below.</p>
                            </div>
                            {this.state.numbers}
                            <div className="number-item-heading1" style={{marginTop:"50px"}}>
                                <p> 
                                Please note that you will always receive <strong>incoming</strong> calls and messages for <strong>all numbers</strong>, regardless of which number is selected.  
                                </p>
                            </div>
                            
                        </div>
                    </div>

                </div>

            </Auxi>

        );

    }

}

export default NumberSelection;

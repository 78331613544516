import React, { useState } from 'react';
import Logo from '../../assets/images/fonus.png';
import '../../assets/css/navbar.css'

const TopNav = props => {
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);

    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light rounded nav-logo">
            <a className="text-info font-weight-bolder" href="https://www.fonusmobile.com" target="_blank">
                <img src={Logo} alt="Logo" width="120" />
            </a>
            
            {/* <button className="custom-toggler navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample09" aria-controls="navbarsExample09" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse navbar-collapse-right`} id="navbarsExample09">
                <ul className={`navbar-nav ml-auto `} >
                    <li className="nav-link text-info-custom" href="/contact">coverage</li>
                    <li className="nav-link text-info-custom" href="/contact">activate</li>
                    <li className="nav-link text-info-custom" href="/login">my account</li>
                </ul>
                <li href="/request-demo" className="btn btn-sm shop-btn nav-link text-white" >shop</li>
            </div> */}
            
        </nav>
    );
}

export default TopNav;
import React from 'react'
import {withRouter,Redirect} from "react-router";
import axios from 'axios';

import { connect } from 'react-redux'
import * as actions from './../Store/actions/index'

import Auxi from '../../hoc/Auxi'
import Header from '../../components/Layout/Header'
import ChangePasswordModal from '../../components/Widgets/Modal/ChangePasswordModal'
import Toast from '../../components/Widgets/CustomToast';
import Spinner from '../../components/Widgets/Spinner';

import './Layout.css'
import Logo from '../../assets/images/Saevolgo_Logo_Full.png'
import Axios from 'axios';

class Layout extends React.Component {

    state = {
        displaySettingsMenuValue: "None",
        userName: 'Daniyal',
        performLogout: false,
        openCDR: false,
        openSpam: false,
        openMetrics: false,
        openEditProfile: false,
        openIndex: false,
        preventAcess: false,
        showChangePasswordModal: false,
        openBlackList: false,
        openWhiteList: false,
        openRateLimt: false,
        loading: false,
    }

    toggleSettingsHandler = (evt) =>{
        evt.preventDefault();
        if(this.state.displaySettingsMenuValue == "None"){
            this.setState({displaySettingsMenuValue: "block"});
        }
        else {
            this.setState({displaySettingsMenuValue: "None"});
        }
    }

    logoutHandler = (evt) => {
        evt.preventDefault();
        this.props.onLogout();
        this.setState({performLogout: true});
    }

    cdrHandler = (evt) => {
        evt.preventDefault();
        this.setState({openCDR: true});
    }
    internalHandler = (evt) => {
        evt.preventDefault();
        this.setState({openInternalList: true});
    }

    spamHandler = (evt) => {
        evt.preventDefault();
        this.setState({openSpam: true});
    }

    metricsHandler = (evt) => {
        evt.preventDefault();
        this.setState({openMetrics: true});
    }

    blackListHandler = (evt) => {
        evt.preventDefault();
        this.setState({openBlackList: true});
    }

    whiteListHandler = (evt) => {
        evt.preventDefault();
        this.setState({openWhiteList: true});
    }

    rateLimitHandler = (evt) =>{
        evt.preventDefault();
        this.setState({openRateLimt: true});
    } 

    indexHandler = (evt) => {
        evt.preventDefault();
        this.setState({openIndex: true});
    }

    editProfileHandler = (evt) => {
        evt.preventDefault();
        this.setState({openEditProfile: true});
    }


    changePasswordHandler = (evt) => {
        evt.preventDefault();
        this.setState({displaySettingsMenuValue: "None"});
        this.setState({showChangePasswordModal: true})
    }

    componentDidMount(){
        if(!localStorage.getItem('subscriber_id')){
            this.setState({preventAcess: true});
        }
        this.setState({userName: localStorage.getItem('userName')});
        this.setState({companyName: localStorage.getItem('companyName')});
    }
    closeModal = (event) =>{
        this.setState({showChangePasswordModal:false});
    }

    render (){
        var redirect = null;

        if(this.state.openCDR){
            this.setState({openCDR: false});
            redirect = <Redirect to = "/cdr" />;
        }

        if(this.state.openInternalList){
            this.setState({openInternalList: false});
            redirect = <Redirect to = "/internal_list" />;
        }

        if(this.state.openIndex){
            this.setState({openIndex: false});
            redirect = <Redirect to = "/metrics" />;
        }

        if(this.state.openSpam){
            this.setState({openSpam: false});
            redirect = <Redirect to = "/spam" />;
        }

        if(this.state.openMetrics){
            this.setState({openMetrics: false});
            redirect = <Redirect to = "/metrics" />;
        }

        if(this.state.openBlackList){
            this.setState({openBlackList: false});
            redirect = <Redirect to = "/black_list" />;
        }

        if(this.state.openWhiteList){
            this.setState({openWhiteList: false});
            redirect = <Redirect to = "/white_list" />;
        }

        if(this.state.openRateLimt){
            this.setState({openRateLimt: false});
            redirect = <Redirect to = "/rate_limit" />;
        }

        if(this.state.openEditProfile){
            this.setState({openEditProfile: false});
            redirect = <Redirect to = "/edit_profile" />;
        }

        if(this.state.performLogout){
            this.setState({performLogout: false});
            localStorage.removeItem('userName');
            localStorage.removeItem('cloud_id');
            localStorage.removeItem('subscriber_id');
            localStorage.removeItem('companyName');
            redirect = <Redirect to = "/" />;
        }

        if (this.state.preventAcess){
            redirect=<Redirect to="/"/>
        }

        return (

            <Auxi>
                {redirect}
                <ChangePasswordModal
                    modalIsOpen={this.state.showChangePasswordModal}
                    closeModal={this.closeModal}
                />
                <div style={{textAlign: "center"}}>
                    <h3 className="m-b-10">
                        {this.state.loading
                            ? <Spinner data="" />
                            : null
                        }
                    </h3>
                </div>
                <nav className="pcoded-navbar custom-navbar-color">
                    <div className="navbar-wrapper">
                        <div className="navbar-brand header-logo bg-White">
                            <a  href="" onClick={this.indexHandler} className="b-brand" >
                                <img className="img-logo" src={Logo}/>
                            </a>

                        </div>
                        <div className="navbar-content scroll-div">
                            <ul className="nav pcoded-inner-navbar">

                            <li data-username="dashboard Default Ecommerce CRM Analytics Crypto Project" className="nav-item">
                                    <a  href="" onClick={this.metricsHandler} className="nav-link color-white">
                                        <span className="pcoded-micon">
                                            <i className="feather icon-bar-chart-2"> </i>
                                        </span>
                                        <span className="pcoded-mtext">METRICS</span>
                                    </a>
                                </li>
                                <hr className="hr-nav"/>

                                <li data-username="dashboard Default Ecommerce CRM Analytics Crypto Project" className="nav-item">
                                    <a  href=""  onClick={this.cdrHandler} className="nav-link color-white">
                                        <span className="pcoded-micon">
                                            <i className="feather icon-file-minus"> </i>
                                        </span>
                                        <span className="pcoded-mtext">CDR</span>
                                    </a>
                                </li>
                                <hr className="hr-nav"/>

                                <li data-username="dashboard Default Ecommerce CRM Analytics Crypto Project" className="nav-item">
                                    <a  href="" onClick={this.internalHandler} className="nav-link color-white">
                                        <span className="pcoded-micon">
                                            <i className="feather icon-plus-square"> </i>
                                        </span>
                                        <span className="pcoded-mtext">Internal List</span>
                                    </a>
                                </li>
                                <hr className="hr-nav"/>

                                <li data-username="dashboard Default Ecommerce CRM Analytics Crypto Project" className="nav-item">
                                    <a  href="" onClick={this.spamHandler} className="nav-link color-white">
                                        <span className="pcoded-micon">
                                            <i className="feather icon-alert-triangle"> </i>
                                        </span>
                                        <span className="pcoded-mtext">SPAM</span>
                                    </a>
                                </li>
                                <hr className="hr-nav"/>

                                <li data-username="dashboard Default Ecommerce CRM Analytics Crypto Project" className="nav-item">
                                    <a  href="" onClick={this.blackListHandler} className="nav-link color-white">
                                        <span className="pcoded-micon">
                                            <i className="feather icon-x-circle"> </i>
                                        </span>
                                        <span className="pcoded-mtext">BLACK LIST</span>
                                    </a>
                                </li>
                                <hr className="hr-nav"/>

                                <li data-username="dashboard Default Ecommerce CRM Analytics Crypto Project" className="nav-item">
                                    <a  href="" onClick={this.whiteListHandler} className="nav-link color-white">
                                        <span className="pcoded-micon">
                                            <i className="feather icon-check-circle"> </i>
                                        </span>
                                        <span className="pcoded-mtext">WHITE LIST</span>
                                    </a>
                                </li>
                                <hr className="hr-nav"/>

                                <li data-username="dashboard Default Ecommerce CRM Analytics Crypto Project" className="nav-item">
                                    <a  href="" onClick={this.rateLimitHandler} className="nav-link color-white">
                                        <span className="pcoded-micon">
                                            <i className="feather icon-clock"> </i>
                                        </span>
                                        <span className="pcoded-mtext">RATE LIMIT</span>
                                    </a>
                                </li>
                                <hr className="hr-nav"/>

                            </ul>
                        </div>
                    </div>
                </nav>
                <Header
                clickSettings = {this.toggleSettingsHandler}
                displaySettingsMenu = {this.state.displaySettingsMenuValue}
                userName = {this.state.userName}
                clickedLogout = {this.logoutHandler}
                companyName = {this.state.companyName}
                clickedProfile = {this.editProfileHandler}
                clickedChangePassword = {this.changePasswordHandler}
                />

            </Auxi>
        );
    }


}
const mapStateToProps = state =>{
    return{

        isAuthenticated: state.auth.token !== null,

    }
};

const mapDispatchToProps = dispatch=>{
    return{
        onLogout: () => dispatch(actions.logout()),

    }
};


export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Layout))

import React from 'react'
import {withRouter,Redirect} from "react-router";

import Auxi from '../../hoc/Auxi';
import Spinner from '../../components/Widgets/Spinner';

import '../../assets/css/style.css'

class Signup extends React.Component{

    state = {
        toLogin: false,
        validated: false,
        passwordValidation: false,
        sendingEmail: false,
    }

    inputValueHandler = (evt) => {
        this.setState({[evt.target.name]: evt.target.value});

        switch(evt.target.name){
            case "age":

                if(evt.target.value == "" ){
                    this.setState({age:null});
                }
                else{
                    this.setState({age: Math.max(Number(1), Math.min(Number(100), Number(evt.target.value)))});
                }
                return;

                // here will come different cases for email and other validations
            default:
                return;
        }

    };

    backToLoginHandler = (event) => {
        event.preventDefault();
        this.setState({toLogin: true});
    };

    signupHandler = (evt) => {
        evt.preventDefault();
        this.setState({sendingEmail: true});
        this.setState({passwordValidation: false});

        if(!this.state.username || !this.state.password || !this.state.email){
            alert("All Fields are mandatory!");
            return;
        }

        if(this.state.password != this.state.confirmPassword){
            this.setState({passwordValidation: true});
            return;
        }



    }
    render(){
        var redirect = null;

        if(this.state.toLogin){
            this.setState({toLogin:false});
            redirect = <Redirect to="/"/>
        }



        return(
            <div>
                {redirect}
                <div className="auth-wrapper aut-bg-img-side cotainer-fiuid align-items-stretch">
                    <div className="row align-items-center w-100 align-items-stretch bg-white">
                        <div className="d-none d-lg-flex col-lg-8 signUpBackgroundImage align-items-center d-flex justify-content-center">
                            <div className="col-md-8">
                                <h1 className="text-white mb-5">Signup in Acrobits External Provisioning</h1>
                                <p className="text-white">
                                    Here you can write some stuff regarding companies missiion statement or something like that.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 align-items-stret align-items-center d-flex justify-content-center">

                            <div className=" auth-content text-center">
                                <h3 className="mb-4">Signup</h3>
                                <div className="input-group mb-3">
                                    <input type="email" className="form-control" placeholder="Email"
                                    value={this.state.email} name="email" onChange={(evt) => this.inputValueHandler(evt)}/>
                                </div>
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" placeholder="User Name"
                                    value={this.state.username} name="username" onChange={(evt) => this.inputValueHandler(evt)}/>
                                </div>
                                <div className="input-group mb-3">
                                    <input type="password" className="form-control" placeholder="Password"
                                    value={this.state.password} name="password" onChange={(evt) => this.inputValueHandler(evt)}/>
                                </div>
                                <div className="input-group mb-3">
                                    <input type="password" className="form-control" placeholder="Confirm Password"
                                    value={this.state.confirmPassword} name="confirmPassword" onChange={(evt) => this.inputValueHandler(evt)}/>
                                </div>

                                {
                                    this.state.passwordValidation?<Auxi><span className='login-error'>
                                    Passwords does not match!</span> <br/><br/></Auxi>
                                    :null
                                }

                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" placeholder="Name"
                                    value={this.state.name} name="name" onChange={(evt) => this.inputValueHandler(evt)}/>
                                </div>

                                <div className="input-group mb-3">
                                    <input type="number" className="form-control" placeholder="Age"
                                    value={this.state.age} name="age" onChange={(evt) => this.inputValueHandler(evt)}
                                    max="100" min="1"/>
                                </div>

                                <button className="btn btn-primary mb-4" onClick = {this.signupHandler}>
                                    {this.state.sendingEmail
                                      ? <Spinner data="SigningUp..." />
                                      : "Signup"
                                    }
                                </button>

                                <p className="mb-0 text-muted">
                                    Already have an account?
                                    <a href="" onClick={this.backToLoginHandler}>Back to Login</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Signup;

import React from 'react';
import { Redirect } from 'react-router';
import { MDBDataTableV5 } from 'mdbreact';
import axios from 'axios';
import moment from 'moment';

import Auxi from '../../hoc/Auxi';
import Layout from '../Layout/Layout';
import Spinner from '../../components/Widgets/Spinner';
import Toast from '../../components/Widgets/CustomToast';

import '../../assets/css/style.css';

class Spam extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            newRecordClicked: false,
            spamIdSelected: null,
            loading: true,
        };
    }

    populateSpamWords = () => {
        const payLoad = {
            subscriber_id: localStorage.getItem('subscriber_id'),
        };

        axios.post(`${process.env.REACT_APP_API_URL}get/spam`, payLoad)
        .then(response=> {

            if((response.data).length === 0){
                this.setState({spamWords: null});
                return;
            }
            
            let spamData = null
            spamData = response.data.map( row => {
                return(
                    <div className="spam-word-container">
                        <span onClick={this.spamWordClickedHandler} data-id={row.id} data-name={row.word}> {row.word} </span>
                        <span onClick={this.deleteHandler} data-id={row.id} data-name={row.word} class="delete-icon-right">X</span>
                    </div>
                );
            });
            this.setState({spamWords: spamData});

        })
        .catch(err=> {
            Toast.error(err.message);
        });
    }

    populateSpamTable = (spamWord) => {
        if(spamWord){
            
            const payLoad = {
                subscriber_id: localStorage.getItem('subscriber_id'),
                spam_word: spamWord
            };
    
            axios.post(`${process.env.REACT_APP_API_URL}get/spam_messages`, payLoad, { withCredentials: true })
            .then(response=> {
                this.createDataTable(response.data);
                console.log("populated table")
    
            })
            .catch(err=> {
                Toast.error(err.message);
            });
        }
        else{
            this.createDataTable([]);
        }
    }

    componentDidMount() {

        this.populateSpamWords();

        this.populateSpamTable(null);

    }

    createDataTable = (dataFromApi) => {

        let columns = [
            
            {
                label: 'From User',
                field: 'from_user',
            },
            {
                label: 'To User',
                field: 'to_user',
            },
            // {
            //     label: 'Message',
            //     field: 'my_message',
            // },
            {
                label: 'Created Time',
                field: 'created_time',
            },
        ];

        if (dataFromApi != null)
        {
            dataFromApi = dataFromApi.map((row) => {
                row.created_time = moment(row.created_time).format("LLL")
    
                return ({ ...row });
            });
        }

        this.setState({ dataTable: { columns: columns, rows: dataFromApi } });
        this.setState({ loading: false });
    };

    addNewHandler = (evt) => {
        evt.preventDefault();
        if(!this.state.word){
            return;
        }

        const payLoad = {
            subscriber_id: localStorage.getItem('subscriber_id'),
            word: this.state.word
        };

        axios.post(`${process.env.REACT_APP_API_URL}spamCreate`, payLoad)
        .then(response=> {

            Toast.success('Spam Word Added');
            this.setState({word: ''});
            this.populateSpamWords();

        })
        .catch(err=> {
            Toast.error(err.message);
        });

    }

    deleteHandler = (evt) => {
        if (window.confirm('Are you sure you want to delete this spam word')) {
            const payLoad = {
                id: evt.target.getAttribute('data-id'),
                word: evt.target.getAttribute('data-name')
            };
    
            axios.post(`${process.env.REACT_APP_API_URL}deleteSpamWord`, payLoad, { withCredentials: true })
            .then(response=> {
                Toast.success('Spam Word Deleted');
                this.populateSpamWords();
                if(this.state.spamWordForMessage === evt.target.getAttribute('data-name')){
                    this.setState({spamWordForMessage: null});
                }
            })
            .catch(err=> {
                Toast.error(err.message);
            });
        }
    }

    spamWordClickedHandler = (evt) => {
        console.log("spam word clicked: " + evt.currentTarget.getAttribute("data-name"));
        this.setState({spamWordForMessage: evt.currentTarget.getAttribute("data-name")});
        this.populateSpamTable(evt.currentTarget.getAttribute("data-name"));
    }

    render() {


        return (
            <Auxi>
                <Layout />

                <div className = "MainIndex">
                    <div className="page-header customTitleMiddle">
                        <h3 className="m-b-10">
                            {this.state.loading
                            ? <Spinner data="SPAM ..." />
                            : 'SPAM'
                            }
                        </h3>

                        
                    </div>

                    <div className="row form-group spam-input">

                        <div className="col-md-8">
                            <input type="text" className="form-control"
                                placeholder="Spam Word" onChange={e => this.setState({word: e.currentTarget.value})} value={this.state.word}/>
                        </div>

                        <div className="col-md-2">
                            <button onClick={this.addNewHandler} type="button"  className="btn btn-rounded-custom btn-primary" > Add </button>
                        </div>

                    </div>

                    <div className="spam-words-container">

                        {/* <div className="spam-word-container">
                            <span> Spam Word</span>
                            <span class="delete-icon-right" data-id="1">X</span>
                        </div> */}

                        {
                            this.state.spamWords?
                                this.state.spamWords
                            :
                                <h1> No Spam Words Found </h1>
                        }   
                    
                    </div>

                    <div className="page-header customTitleMiddle">

                    {
                        this.state.spamWordForMessage?
                            <h4> Messages Having Spam Word "{this.state.spamWordForMessage}" </h4>
                        :null
                    }

                    </div>
                    
                    <div>
                        <MDBDataTableV5 hover entriesOptions={[5, 20, 25]} entries={5}
                            pagesAmount={4} data={this.state.dataTable} searchTop  searchBottom={false} />

                    </div>
                </div>

            </Auxi>

        );

    }

}

export default Spam;

import React from 'react';
import { Redirect } from 'react-router';
import { MDBDataTableV5 } from 'mdbreact';
import axios from 'axios';
import moment from 'moment';

import Auxi from '../../hoc/Auxi';
import Layout from '../Layout/Layout';
import Spinner from '../../components/Widgets/Spinner';
import Toast from '../../components/Widgets/CustomToast';

import '../../assets/css/style.css';

class Internal extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            newRecordClicked: false,
            internalCodeIdSelected: null,
            loadingInternalCodes: true,
            loadingAllowedNumbers: true,
        };
    }

    populateInternalCodes = () => {
        this.setState({loadingInternalCodes: true});
        const payLoad = {
            subscriber_id: localStorage.getItem('subscriber_id'),
        };

        axios.post(`${process.env.REACT_APP_API_URL}get/internal_codes`, payLoad)
        .then(response=> {

            if((response.data).length === 0){
                this.setState({internalCodes: null});
                this.setState({loadingInternalCodes: false});
                return;
            }
            
            let listData = null
            listData = response.data.map( row => {
                return(
                    <div className="spam-word-container">
                        <span data-id={row.id} data-name={row.code}> {row.code} </span>
                        <span onClick={this.deleteInternalCodeHandler} data-id={row.id} data-name={row.code} class="delete-icon-right">X</span>
                    </div>
                );
            });
            this.setState({internalCodes: listData});
            this.setState({loadingInternalCodes: false});

        })
        .catch(err=> {
            Toast.error(err.message);
            this.setState({loadingInternalCodes: false});
        });
    }

    populateAllowedNumbers = () => {
        this.setState({loadingAllowedNumbers: true});
        const payLoad = {
            subscriber_id: localStorage.getItem('subscriber_id'),
        };

        axios.post(`${process.env.REACT_APP_API_URL}get/allowed_numbers`, payLoad)
        .then(response=> {

            if((response.data).length === 0){
                this.setState({allowedNumbers: null});
                this.setState({loadingAllowedNumbers: false});
                return;
            }
            
            let listData = null
            listData = response.data.map( row => {
                return(
                    <div className="spam-word-container">
                        <span data-id={row.id} data-name={row.number}> {row.number} </span>
                        <span onClick={this.deleteAllowedNumberHandler} data-id={row.id} data-name={row.number} class="delete-icon-right">X</span>
                    </div>
                );
            });
            this.setState({allowedNumbers: listData});
            this.setState({loadingAllowedNumbers: false});

        })
        .catch(err=> {
            Toast.error(err.message);
            this.setState({loadingAllowedNumbers: false});
        });
    }

    componentDidMount() {

        this.populateInternalCodes();

        this.populateAllowedNumbers();

    }

    addNewInternalCodeHandler = (evt) => {
        evt.preventDefault();
        if(!this.state.code){
            return;
        }
        this.setState({loadingInternalCodes: true});
        const payLoad = {
            subscriber_id: localStorage.getItem('subscriber_id'),
            code: this.state.code
        };

        axios.post(`${process.env.REACT_APP_API_URL}internalCodeCreate`, payLoad)
        .then(response=> {

            Toast.success('Code Added to Internal Codes');
            this.setState({code: ''});
            this.populateInternalCodes();

        })
        .catch(err=> {
            Toast.error(err.message);
            this.setState({loadingInternalCodes: false});
        });

    }

    addNewAllowedNumberHandler = (evt) => {
        evt.preventDefault();
        if(!this.state.number){
            return;
        }
        this.setState({loadingAllowedNumbers: true});
        const payLoad = {
            subscriber_id: localStorage.getItem('subscriber_id'),
            number: this.state.number
        };

        axios.post(`${process.env.REACT_APP_API_URL}allowedNumberCreate`, payLoad)
        .then(response=> {

            Toast.success('Number Added to Allowed Numbers');
            this.setState({number: ''});
            this.populateAllowedNumbers();

        })
        .catch(err=> {
            Toast.error(err.message);
            this.setState({loadingAllowedNumbers: false});
        });

    }

    deleteInternalCodeHandler = (evt) => {
        if (window.confirm('Are you sure you want to delete this internal code')) {
            this.setState({loadingInternalCodes: true});
            const payLoad = {
                id: evt.target.getAttribute('data-id'),
                number: evt.target.getAttribute('data-name')
            };
    
            axios.post(`${process.env.REACT_APP_API_URL}deleteInternalCode`, payLoad, { withCredentials: true })
            .then(response=> {
                Toast.success('Internal Code Deleted');
                this.populateInternalCodes();
                
            })
            .catch(err=> {
                Toast.error(err.message);
                this.setState({loadingInternalCodes: false});
            });
        }
    }

    deleteAllowedNumberHandler = (evt) => {
        if (window.confirm('Are you sure you want to delete this allowed number')) {
            const payLoad = {
                id: evt.target.getAttribute('data-id'),
                number: evt.target.getAttribute('data-name')
            };
            this.setState({loadingAllowedNumbers: true});
            axios.post(`${process.env.REACT_APP_API_URL}deleteAllowedNumber`, payLoad, { withCredentials: true })
            .then(response=> {
                Toast.success('Allowed Number Deleted');
                this.populateAllowedNumbers();
                
            })
            .catch(err=> {
                Toast.error(err.message);
                this.setState({loadingAllowedNumbers: false});
            });
        }
    }

    render() {


        return (
            <Auxi>
                <Layout />

                <div className = "MainIndex">
                    <div className="page-header customTitleMiddle">
                        <h3 className="m-b-10">
                            {this.state.loadingInternalCodes
                            ? <Spinner data="Internal Codes ..." />
                            : 'Internal Codes'
                            }
                        </h3>

                        
                    </div>

                    <div className="row form-group spam-input">

                        <div className="col-md-8">
                            <input type="text" className="form-control"
                                placeholder="Enter Internal Code" onChange={e => this.setState({code: e.currentTarget.value})} value={this.state.code}/>
                        </div>

                        <div className="col-md-2">
                            <button onClick={this.addNewInternalCodeHandler} type="button"  className="btn btn-rounded-custom btn-primary" > Add </button>
                        </div>

                    </div>

                    <div className="spam-words-container">

                        {
                            this.state.internalCodes?
                                this.state.internalCodes
                            :
                                <h1> No Internal Code Found </h1>
                        }   
                    
                    </div>

                    <div className="page-header customTitleMiddle">
                        <h3 className="m-b-10">
                            {this.state.loadingAllowedNumbers
                            ? <Spinner data="Allowed Numbers ..." />
                            : 'Allowed Numbers'
                            }
                        </h3>

                        
                    </div>


                    <div className="row form-group spam-input">

                        <div className="col-md-8">
                            <input type="text" className="form-control"
                                placeholder="Enter Allowed Number" onChange={e => this.setState({number: e.currentTarget.value})} value={this.state.number}/>
                        </div>

                        <div className="col-md-2">
                            <button onClick={this.addNewAllowedNumberHandler} type="button"  className="btn btn-rounded-custom btn-primary" > Add </button>
                        </div>

                    </div>

                    <div className="spam-words-container">

                        {
                            this.state.allowedNumbers?
                                this.state.allowedNumbers
                            :
                                <h1> No Allowed Number Found </h1>
                        }   
                    
                    </div>

                    
                </div>

            </Auxi>

        );

    }

}

export default Internal;

import React from 'react';
import { Redirect } from 'react-router';
import axios from 'axios';

import Auxi from '../../hoc/Auxi';
import Spinner from '../../components/Widgets/Spinner';
import Toast from '../../components/Widgets/CustomToast';

import VoiceRecorderModal from './VoiceRecorderModal'

import '../../assets/css/voicemailGreeting.css';

class VoicemailGreeting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cloudId: "FONUSMOBILE",
            backToMyAccount: false,
            selectedVoicemailType: "default",
            showRecorder: false,
            greetingPath: "",
            loading: true,
            audioDetails: {
                url: null,
                blob: null,
                chunks: null,
                duration: {
                    h: 0,
                    m: 0,
                    s: 0
                }
            },
            havePermissions: false,

        };
        //this.checkPermissions = this.checkPermissions.bind(this);
    }
    audioRef = null;

    // checkPermissions = () => {
    //     const permissions = navigator.mediaDevices.getUserMedia({ audio: true })
    //     permissions.then((stream) => {
    //         this.setState({ havePermissions: !this.state.havePermissions })
    //     })
    //     .catch((err) => {
    //         this.setState({ havePermissions: false });
    //         console.log(`${err.name} : ${err.message}`)
    //     });
    // }

    componentDidMount() {
        const encodedUrlString = window.location.href.replace(/\+/g, '%2B');
        const url = new URL(encodedUrlString);
        const cloudUsername = url.searchParams.get("cloud_username");
        const cloudPassword = url.searchParams.get("cloud_password");
        this.setState({ cloudUsername: cloudUsername });
        this.setState({ cloudPassword: cloudPassword });
        document.title = 'Number Selection - Fonus';
        // Safari 3.0+ "[object HTMLElementConstructor]" 
        let isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
        console.log("is safari ---> ", isSafari)
        const userAgent = navigator.userAgent.toLowerCase();

        if (userAgent.includes("android")) {
            this.setState({ mimeType: "audio/webm" });
        } else if (userAgent.includes("iphone") || userAgent.includes("ipad") || userAgent.includes("ipod")) {
            this.setState({ mimeType: "video/mp4" });
        } else {
            this.setState({ mimeType: "video/mp4" });
        }
       
        this.getGreetingDetails(cloudUsername, cloudPassword);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.audioRef && this.state.greetingPath !== prevState.greetingPath) {
            this.audioRef.load();
        }

    }

    getGreetingDetails = (cloudUsername, cloudPassword) => {
        const payLoad = {
            cloud_username: cloudUsername,
            cloud_password: cloudPassword,
            cloud_id: this.state.cloudId
        };

        axios.post(`${process.env.REACT_APP_API_URL}getVoicemailGreeting`, payLoad)
            .then(response => {

                if (response.data.greeting_type) {
                    this.setState({ selectedVoicemailType: response.data.greeting_type })
                }
                if (response.data.greeting_type === "custom") {
                    this.setState({ greetingPath: response.data.greeting_path })
                } else {
                    this.setState({ greetingPath: response.data.default_path })
                }
                this.setState({ customGreetingPath: response.data.greeting_path })
                this.setState({ defaultGreetingPath: response.data.default_path })

                this.setState({ loading: false });
            }).catch(err => {
                // console.log('err.response.data',err.response.data)
                // Toast.error(err.response.data)
                this.setState({ loading: false });
            });
    }


    voicemailChangeHandler = (value) => {
        if (value === this.state.selectedVoicemailType) {
            return;
        }
        this.setState({ selectedVoicemailType: value });
        this.setState({ loading: true });
        const payLoad = {
            cloud_username: this.state.cloudUsername,
            cloud_password: this.state.cloudPassword,
            cloud_id: this.state.cloudId,
            greeting_type: value,
        };

        axios.post(`${process.env.REACT_APP_API_URL}changeVoicemailGreeting`, payLoad,)
            .then(response => {
                this.setState({ loading: false });
                if (value === "custom") {
                    this.setState({ greetingPath: this.state.customGreetingPath })
                } else {
                    this.setState({ greetingPath: this.state.defaultGreetingPath })
                }
            }).catch(err => {
                Toast.error(err.response.data)
                this.setState({ loading: false });
            });
    }

    handleAudioStop = (data) => {
        this.setState({ audioDetails: data });
    }

    handleAudioUpload = (file) => {
        if (file === null) {
            return;
        }
        this.setState({ showRecorder: false });
        let payLoad = new FormData();
        payLoad.append('cloud_username', this.state.cloudUsername);
        payLoad.append('cloud_password', this.state.cloudPassword);
        payLoad.append('cloud_id', this.state.cloudId);
        payLoad.append('type', 'audio');
        payLoad.append('greeting_type', this.state.selectedVoicemailType);
        payLoad.append('file', file);

        axios.post(`${process.env.REACT_APP_API_URL}uploadVoicemailGreeting`, payLoad)
            .then(response => {
                if (this.state.selectedVoicemailType === "custom") {
                    this.setState({ greetingPath: response.data.greeting_path })
                }
                this.setState({ customGreetingPath: response.data.greeting_path })
                this.handleReset();
                Toast.success("Voicemail greeting saved successfully!")

            })
            .catch(err => {

                Toast.error("Failed to upload recording")

            });
    }

    handleCountDown = (data) => {
        //console.log(data);
    }

    handleReset = () => {
        const reset = {
            url: null,
            blob: null,
            chunks: null,
            duration: {
                h: 0,
                m: 0,
                s: 0
            }
        };
        this.setState({ audioDetails: reset });
    }

    closeModal = () => {
        this.setState({ showRecorder: false });
        this.handleReset();
    }
    OpenRecorder = () => {
        this.setState({ showRecorder: true })
        //this.checkPermissions()
    }

    render() {
        let redirect = null;
        if (this.state.backToMyAccount) {
            this.setState({ backToMyAccount: false });
            redirect = <Redirect to={"/my_account?cloud_username=" + this.state.cloudUsername + "&cloud_password=" + this.state.cloudPassword} />;

        }

        return (
            <Auxi>
                {redirect}
                <VoiceRecorderModal
                    modalIsOpen={this.state.showRecorder}
                    closeModal={this.closeModal}
                    audioDetails={this.state.audioDetails}
                    handleAudioStop={this.handleAudioStop}
                    handleAudioUpload={this.handleAudioUpload}
                    handleCountDown={this.handleCountDown}
                    mimeType={this.state.mimeType}
                    handleReset={this.handleReset}
                />
                <div className="number-selection-page">
                    <div className="row outer-container">
                        <h3 className="m-b-10 loader-center">
                            {this.state.loading
                                ? <Spinner data="" />
                                : null
                            }
                        </h3>
                        <div className="col-md-12 number-selection-heading">
                            <i className="feather icon-chevron-left" onClick={e => this.setState({ backToMyAccount: true })}> </i>
                            <h1> Voicemail Greeting </h1>
                        </div>

                        <div className="col-md-12">
                            <div className="number-item-heading1">
                                <p>
                                    Set your voicemail greeting
                                </p>
                            </div>
                            <div className="number-container">
                                <h4 onClick={e => this.voicemailChangeHandler("default")}>
                                    Default
                                </h4>
                                {
                                    this.state.selectedVoicemailType === "default" ?
                                        <span>
                                            <i className="feather icon-check"> </i>
                                        </span>
                                        : null
                                }
                            </div>
                            <div className="number-container">
                                <h4 onClick={e => this.voicemailChangeHandler("custom")}>
                                    Custom
                                </h4>
                                {
                                    this.state.selectedVoicemailType === "custom" ?
                                        <span>
                                            <i className="feather icon-check"> </i>
                                        </span>
                                        : null
                                }
                            </div>
                            <div className="audio-container">
                                {
                                    this.state.greetingPath ?
                                        <audio controls controlsList="nodownload noplaybackrate"
                                            ref={(refe) => this.audioRef = refe}>
                                            <source src={`${process.env.REACT_APP_API_URL}` + this.state.greetingPath.slice(1)}
                                                type={"audio/wav"} />
                                            Your browser does not support the audio element.
                                        </audio>
                                        : null
                                }

                            </div>

                            <div className="record-btn">
                                <button onClick={this.OpenRecorder}>Record</button>
                            </div>

                        </div>
                    </div>

                </div>

            </Auxi>

        );

    }

}

export default VoicemailGreeting;


import React from 'react';


class Index extends React.Component{

    componentDidMount() {

        window.location.replace("https://www.fonusmobile.com/");
    }


    render() {


        return (
            null

        );

    }

}

export default Index;

import * as ActionTypes from './ActionTypes';
import axios from 'axios';

export const authSuccess = (token) =>{
    return {
        type: ActionTypes.AUTH_SUCCESS,
        access: token,
    }
};

export const authFail =(error)=>{
    return{
        type: ActionTypes.AUTH_FAIL,
        error: error
    }
};

export const errorNull = ()=>{
    return{
        type: ActionTypes.AUTH_ERROR,
        error:null
    }
};

export const logout =()=>{

    return {
        type: ActionTypes.AUTH_LOGOUT
    }
};


export const auth=(cloud_id, user, password)=>{
    return dispatch=>{

        const payload={
            cloud_id: cloud_id,
            user: user,
            password: password
        };
        console.log('Calling Axios: cloud_id:' + cloud_id + ', user: '+user+ ', password: '+password);
        axios.post(`${process.env.REACT_APP_API_URL}authLogin`,payload,{credentials: 'include'})
            .then(response=>{

                
                localStorage.setItem('userName',user);
                localStorage.setItem('cloud_id', cloud_id);
                localStorage.setItem('subscriber_id', response.data.id);
                localStorage.setItem('companyName', response.data.company);
                dispatch(authSuccess(cloud_id));
            })
            .catch(err=>{
              // console.log('------------------------------------------');
              // console.log('Status is: '+JSON.stringify(err));
              // console.log('in axios error: cloud_id:' + cloud_id + ', user: '+user+ ', password: '+password);
              // console.log('------------------------------------------');
                console.log(err);
                dispatch(authFail());
            });

    }
};

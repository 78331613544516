import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Route from "./components/Routes/Routes"

function App() {
  return (
    <div>
      <ToastContainer/>
		  <Route/>
    </div>
  );
}

export default App;

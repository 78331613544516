import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync } from '@fortawesome/free-solid-svg-icons'
import '../../assets/css/style.css';

export default props =>
  <div className="fadeIn" >
    <FontAwesomeIcon icon={faSync} className = "fa-spin" style={{marginRight:"10px"}}/>
    <span className="m-b-10">{props.data}</span>
  </div>

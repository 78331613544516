import React from 'react';
import {BrowserRouter as Router,Route} from "react-router-dom";
import Login from '../../containers/Auth/Login';
import Signup from '../../containers/Auth/Signup';

import Cdr from '../../containers/CDR/CDR';
import Spam from '../../containers/Spam/Spam';

import PreActivation from '../../containers/Preactivation/Preactivation';
import NumberSelection from '../../containers/MyAccount/NumberSelection';
import VoicemailGreeting from '../../containers/MyAccount/VoicemailGreeting';
import MyAccount from '../../containers/MyAccount/Index';
import AddOns from '../../containers/MyAccount/AddOns';

import Index from '../../containers/Index';

import Internal from '../../containers/Internal/Internal'

//Pages
import EditProfile from '../../containers/Pages/EditProfile';

const routes = (props) => (
  <Router>

    <div>
    <Route exact path="/" component={Index}/>
        <Route exact path="/login" component={Login}/>
        <Route path="/signup" component={Signup}/>
      
        <Route exact path='/cdr' component={Cdr}/>
        <Route exact path='/internal_list' component={Internal}/>
        <Route exact path='/spam' component={Spam}/>
        
        <Route exact path='/edit_profile' component={EditProfile}/>

        <Route exact path='/pre_activation' component={PreActivation}/>
        <Route exact path='/number_selection' component={NumberSelection}/>
        <Route exact path='/voicemail_greeting' component={VoicemailGreeting}/>
        <Route exact path='/my_account' component={MyAccount}/>
        <Route exact path='/add_ons' component={AddOns}/>

    </div>

  </Router>
);

export default routes;

import React from 'react'
import {withRouter,Redirect} from "react-router";
import { connect } from 'react-redux'

import * as actions from './../Store/actions/index'
import Spinner from '../../components/Widgets/Spinner';
import Logo from '../../assets/images/Saevolgo_Logo_Full.png';
//import './Login.css'
import '../../assets/css/style.css'

class Login extends React.Component{

    state = {
        userName: "",
        password: "",
        cloud_id: "FONUSMOBILE",
        toSignup: false,
        loggingIn: false,
        loginValidationShow: false,
    }

    inputValueHandler = (evt) => {
            this.setState({[evt.target.name]: evt.target.value});
            if(this.state.loginValidationShow){
                this.setState({loginValidationShow:false});
            }
    };

    signupHandler = (event) =>{
        event.preventDefault();
        this.setState({toSignup: true});
    }

    loginHandler = (evt) => {
        evt.preventDefault();
        this.setState({loggingIn: true});
        this.setState({loginValidationShow:true});
        this.props.onAuth(this.state.cloud_id, this.state.userName, this.state.password);
    }
    render(){
        var redirect = null, validationState=null, validationState = false, signingIn = false;

        if(this.props.isAuthenticated){
            redirect = <Redirect to="/metrics"/>
        }

        if(this.state.toSignup){
            redirect = <Redirect to="/signup"/>
        }
        if(this.state.loggingIn){
            signingIn = true;
        }
        if(this.props.isNotAuthenticated){
            validationState = true;
            signingIn = false;
        }
        else {
            validationState = false;
        }
        if(!this.state.loginValidationShow){
            validationState = false;
        }

        return(
            <div>
            {redirect}
            <div className="auth-wrapper signUpBackgroundImage">
                    <div className="auth-content">
                        <div className="card">
                            <div class="col-md-12 theme-bg align-items-center justify-content-center custom-sigin-logo">
                                <img src={Logo} alt="lock images" class="img-fluid" />
                            </div>
                            <div className="card-body text-center">
                                <h3 className="mb-4">Sign In</h3>
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" placeholder="Username"
                                    value={this.state.userName} name="userName" onChange={(evt) => this.inputValueHandler(evt)}/>
                                </div>
                                <div className="input-group mb-4">
                                    <input type="password" className="form-control" placeholder="Password"
                                    value={this.state.password} name="password" onChange={(evt) => this.inputValueHandler(evt)}/>
                                </div>

                                {
                                    validationState?
                                        <div className="mb-4">
                                            <span className='login-error'>Sign In Credentials Invalid!</span>
                                        </div>
                                    :
                                        null
                                }

                                <button className="btn btn-primary mb-4" onClick = {this.loginHandler}>
                                    {signingIn
                                        ? <Spinner data="Signing In..." />
                                        : "Sign In"
                                    }
                                </button>
                                
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
const mapStateToProps = state =>{
    return{

        isAuthenticated: state.auth.token !== null,
        isNotAuthenticated: state.auth.error !==null

    }
};

const mapDispatchToProps = dispatch=>{
    return{
        onAuth:(cloud_id,email,password)=> dispatch(actions.auth(cloud_id,email,password)),
        errorNull:()=> dispatch(actions.errorNull())
    }
};

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Login))

    import React from 'react';
    import { Redirect } from 'react-router';
    import axios from 'axios';

    import '../../assets/css/style.css';
    import Auxi from '../../hoc/Auxi';
    import Layout from '../../containers/Layout/Layout';
    import Spinner from '../../components/Widgets/Spinner';
    import Toast from '../../components/Widgets/CustomToast';

    class EditProfile extends React.Component {

        constructor(props) {
            super(props);
            this.inputChangeHandler = this.inputChangeHandler.bind(this);
            this.saveHandler = this.saveHandler.bind(this);
            this.cancelHandler = this.cancelHandler.bind(this);
        }

        state = {
            loading: true,
            data: {},
            backToIndex: false,
        };

        inputChangeHandler(event) {
            var data = this.state.data;
            data[event.target.name] = event.target.value;
            this.setState({ data });

        }

        componentDidMount() {

            this.setState({ loading: true });
            const payLoad = {
                username : localStorage.getItem('userName'),
                cloud_id : localStorage.getItem('cloud_id')
            };

            axios.post(`${process.env.REACT_APP_API_URL}getProfileDetails`, payLoad, { withCredentials: true }).then(response => {
                var data = this.state.data;
                data = response.data;
                this.setState({ data });
                this.setState({ loading: false });
            }).catch(err => {
                Toast.error(err.message);
            });
            

        }

        saveHandler(event) {
            
            event.preventDefault();
            if (!this.state.data.company || !this.state.data.username ||
            !this.state.data.phone_number || !this.state.data.email ) {
            Toast.error('All Fields are Mandatory!');
            return;
            }
            const payLoad = this.state.data;

            axios.post(`${process.env.REACT_APP_API_URL}updateProfile`, payLoad, { withCredentials: true }).then(response => {

                localStorage.setItem('userName',this.state.data.username);
                localStorage.setItem('companyName', this.state.data.company);
                Toast.success("Successfully Updated the profile");
                this.setState({ backToIndex: true });

            }).catch(err => {
                Toast.error(err.message);
            });
        }

        cancelHandler() {
            this.setState({ backToIndex: true });
        }

        onImageChange = event => {
            if (event.target.files && event.target.files[0]) {
                let img = event.target.files[0];
                this.setState({
                image: URL.createObjectURL(img)
                });

                const payload = {
                    cloud_id: localStorage.getItem('cloud_id'),
                    username: localStorage.getItem('userName')
                }
                var formData = new FormData();
                formData.append("image",event.target.files[0]);
                formData.append("user_id", this.state.data.id)                

                axios.post(`${process.env.REACT_APP_API_URL}uploadProfileImage`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(function () {
                    console.log('Successfully Image Uploaded!');
                })
                .catch(function () {
                    console.log('Failed to Upload Imag!');
                });
            }
        };

        render() {

            let redirect = null;
            if(this.state.backToIndex){
            redirect = <Redirect to = {{
                pathname: "/metrics"
            }} />;
            }

            return (
                <Auxi>
                    {redirect}
                    <Layout/>
                    <div className="MainIndex">

                        <div className="font-body">
                            <div className="col-sm-12  customTitleMiddle">
                                <h3>
                                    Edit Profile   
                                </h3>
                            </div>
                            <div className="row">
                                <div className="col-sm-0 col-md-2"></div>
                                <div className="col-sm-12 col-md-8 extensions-input-fields ">

                                    <div className="row form-group">
                                        
                                        <div className="col-md-6">
                                            <label className="float-left">
                                                Company
                                            </label>
                                            <input type="text" className="form-control" name="company"
                                                onChange={this.inputChangeHandler} value={this.state.data.company}/>
                                        </div>

                                        <div className="col-md-6">
                                            <label className="float-left">
                                                Username
                                            </label>
                                            <input type="text" className="form-control" name="username"
                                                onChange={this.inputChangeHandler} value={this.state.data.username}/>
                                        </div>

                                    </div>

                                    <div className="row form-group">

                                        <div className="col-md-6">
                                            <label className="float-left">
                                                Phone Number
                                            </label>
                                            <input type="text" className="form-control" name="phone_number"
                                                onChange={this.inputChangeHandler} value={this.state.data.phone_number}/>
                                        </div>

                                        <div className="col-md-6">
                                            <label className="float-left">
                                                Email
                                            </label>
                                            <input type="text" className="form-control" name="email"
                                                onChange={this.inputChangeHandler} value={this.state.data.email}/>
                                        </div>

                                    </div>

                                    <div className="row form-group">

                                        {/* <div className="col-md-6">
                                            <div className="pro-head pro-head-custom">
                                                <img  src={this.state.image} className="img-radius" />
                                                
                                            </div>
                                        </div> */}

                                        <div className="col-md-6">
                                            <label className="float-left">
                                                Change Profile Picture
                                            </label>
                                            <input type="file" name="myImage" onChange={this.onImageChange} accept="image/png"/>
                                        </div>

                                    </div>

                                    <div className="row form-group">
                                        <div className="col-md-12">

                                            <button type="button" onClick={this.cancelHandler} className="button-custom btn shadow-2 btn-secondary btn-width">
                                                CANCEL
                                            </button>
                                            <button type="button" onClick={this.saveHandler} className="button-custom btn shadow-2 btn-success btn-width">
                                                SAVE
                                            </button>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-0 col-md-2"></div>
                            </div>
                        </div>

                    </div>

                </Auxi>
            );

        }
    }

    export default EditProfile;
